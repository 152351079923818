import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import CustomerSatisfactionFeatures from "components/ui/extended/CustomerSatisfactionFeatures";
import FullWidthImage from "components/ui/base/FullWidthImage";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import ReadPost from "components/ui/base/ReadPost";
import { SurveyTemplate } from "app-types/template";
import SurveyTemplates from "components/ui/extended/SurveysTemplates";
import BlogPostsBox from "components/ui/base/BlogPostsBox";
import PlainHeader from "components/ui/base/PlainHeader";
import BentoBoxCustomerSatisfactionSolutions from "components/ui/extended/BentoBoxCustomerSatisfactionSolutions";

interface CustomerSatisfactionPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    allSurveysTemplates: {
      nodes: SurveyTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: CustomerSatisfactionPageProps) => {
  const { t } = useTranslation();

  const surveyTemplates = data.allSurveysTemplates.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.customer-satisfaction.seo.title")}
        description={t("pages.customer-satisfaction.seo.description")}
        image={{
          relativePath: "meta/customer-satisfaction-software.jpg",
          alt: "Customer satisfaction Software to make your customers happier",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.customer-satisfaction.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.customer-satisfaction.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.customer-satisfaction.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.customer-satisfaction.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.customer-satisfaction.title")}
        description={t("pages.customer-satisfaction.description")}
        titleMaxWidth={900}
        descriptionMaxWidth={900}
        ctaRegister={{ show: true }}
        ctaGetDemo={{
          show: true,
        }}
      />

      <BentoBoxCustomerSatisfactionSolutions />

      <Customers />

      <ReadPost slug={"customer-satisfaction-metrics"} />

      <FullWidthImage
        title={t("pages.customer-satisfaction.nps.title")}
        description={t("pages.customer-satisfaction.nps.description")}
        cta={{
          text: t("pages.customer-satisfaction.nps.cta"),
          link: "/net-promoter-score/",
        }}
        image={{
          relativePath:
            "pages/customer-satisfaction-tool/customer-satisfaction-nps-survey.jpg",
          alt: t("pages.customer-satisfaction.nps.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.customer-satisfaction.csat.title")}
        description={t("pages.customer-satisfaction.csat.description")}
        cta={{
          text: t("pages.customer-satisfaction.csat.cta"),
          link: "/surveys/",
        }}
        image={{
          relativePath:
            "pages/customer-satisfaction-tool/customer-satisfaction-in-chat-survey.jpg",
          alt: t("pages.customer-satisfaction.csat.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.customer-satisfaction.funnels.title")}
        description={t("pages.customer-satisfaction.funnels.description")}
        cta={{
          text: t("pages.customer-satisfaction.funnels.cta"),
          link: "/marketing-funnel/",
        }}
        image={{
          relativePath:
            "pages/customer-satisfaction-tool/customer-satisfaction-software.jpg",
          alt: t("pages.customer-satisfaction.funnels.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <SurveyTemplates showMore templates={surveyTemplates.slice(0, 5)} />

      <CustomerSatisfactionFeatures
        customTitle={t("pages.customer-satisfaction.featuresTitle")}
      />

      <RegisterForCustomerService />

      <BlogPostsBox
        category={"customer-satisfaction"}
        title={"Customer Satisfaction Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"live-chat"}
      />
    </GlobalWrapper>
  );
};

export const CustomerSatisfactionQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/customer-satisfaction-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allSurveysTemplates {
      nodes {
        background
        description
        emoji
        survey_template_id
        name
        questions {
          title
          subtitle
          choices {
            value
          }
        }
        survey {
          name
          thankyou_text
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
