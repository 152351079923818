import React, { FunctionComponent } from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/global.style";
import BentoBoxItem from "components/ui/base/BentoBoxItem";

const BentoBoxCustomerSatisfactionSolutions: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Container fluid>
        <Row>
          <Col lg={7} sm={12}>
            <Row>
              <BentoBoxItem
                relativePath={
                  "pages/customer-satisfaction-tool/CSAT-survey.png"
                }
                imageAlt={"CSAT Survey"}
                title={"CSAT Survey & Team Insights"}
              />
            </Row>
            <Row>
              <BentoBoxItem
                relativePath={
                  "pages/customer-satisfaction-tool/nps-feedback.png"
                }
                imageAlt={"NPS and Feedback"}
                title={"NPS Insights"}
              />
            </Row>
          </Col>
          <Col lg={5} sm={12}>
            <BentoBoxItem
              relativePath={
                "pages/customer-satisfaction-tool/in-app-survey.png"
              }
              imageAlt={"In-app Surveys"}
              title={"Custom In-app Surveys"}
            />
          </Col>
        </Row>
      </Container>
    </PageContainer>
  );
};

export default BentoBoxCustomerSatisfactionSolutions;
